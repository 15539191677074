@import './../../../../scss/theme-bootstrap';

.footer-links {
  &__content {
    .footer-links__title {
      cursor: pointer;
      @include breakpoint($landscape-up) {
        cursor: default;
        pointer-events: none;
        font-weight: 700;
      }
    }
    .footer-links__mobile-content-toggle {
      display: inline-block;
      float: $rdirection;
      height: 20px;
      width: 20px;
      transform: rotate(90deg);
      transition: backgroound 0.3s ease-in;
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    .footer-links__section {
      height: auto;
    }
  }
  &__link {
    font-size: 14px;
    padding-top: 10px;
    a {
      color: inherit;
      text-decoration: none;
    }
    &-title {
      color: inherit;
    }
  }
  &__title {
    font-family: $font--avenir-black;
  }
  .ot-sdk-show-settings {
    color: inherit;
    text-decoration: none;
    font-size: 14px;
    padding-top: 10px;
    font-weight: normal;
    cursor: pointer;
  }
}
